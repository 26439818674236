import React from 'react'
import { MDBContainer, MDBFooter } from 'mdbreact'

const Footer = () => {
  return (
    <MDBFooter className="d-none d-md-block fixed-bottom pt-0">
      <div className="text-center py-3">
        <MDBContainer fluid>
          Please reach out to us for more information.
        </MDBContainer>
      </div>
      <div className="footer-copyright text-center py-3">
        <MDBContainer fluid>
          &copy; {new Date().getFullYear()} Copyright: McGee Clan LLC
        </MDBContainer>
      </div>
    </MDBFooter>
  )
}

export default Footer
