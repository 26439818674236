import React, { Component } from 'react'
import Layout from '../components/layout'
import Intro from '../components/mask'
import SEO from '../components/seo'
import Carousel from '../components/carousel'

class App extends Component {
  render() {
    return (
      <>
        <Layout>
          <SEO
            title="Home"
            keywords={[
              `McGee Clan`,
              'Business',
              'Cloud',
              'Consulting Services',
              'Convenience Services',
              'McGee Clan LLC',
            ]}
          />
          <Carousel />
          <Intro />
        </Layout>
      </>
    )
  }
}

export default App
